import { useCallback, useMemo } from 'react';

import { Checkbox, Radio, VSpacing, Text, UncontrolledInput, Button } from '@hh.ru/magritte-ui';
import BlokoButton, { ButtonKind } from 'bloko/blocks/button';
import BlokoCheckbox from 'bloko/blocks/checkbox';
import { FormItem } from 'bloko/blocks/form';
import Gap from 'bloko/blocks/gap';
import InputText, { InputScale } from 'bloko/blocks/inputText';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import BlokoRadio from 'bloko/blocks/radio';
import BlokoText from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { AppDispatch } from 'src/models/appDispatch';
import { FoldersGroupType, FoldersInvariants } from 'src/models/resumeFolders';

import Folder from 'src/components/ResumeFolders/Folder';
import useResumeFolder, { SelectType } from 'src/components/ResumeFolders/useResumeFolder';

import styles from './styles.less';

export { SelectType } from 'src/components/ResumeFolders/useResumeFolder';

const TrlKeys = {
    myFolders: 'employer.resumeFolders.myFolders',
    createFolder: 'employer.resumeFolders.createFolder',
    newFolder: 'employer.resumeFolders.newFolder',
    sharedFolders: 'employer.resumeFolders.sharedFolders',
    submit: 'employer.resumeFolders.save.checkbox',
};

interface ResumeFoldersProps {
    folders: FoldersInvariants;
    dataQAPrefix: string;
    selectedIdsFolder: string[];
    selectType?: SelectType;
    onSave: (arg: {
        foldersInvariants: FoldersInvariants;
        dispatch: AppDispatch;
        checkedFolders?: string[];
    }) => Promise<void>;
    isMagritte?: boolean;
}

const ResumeFolders: TranslatedComponent<ResumeFoldersProps> = ({
    trls,
    folders,
    selectedIdsFolder = [],
    dataQAPrefix,
    onSave,
    selectType = SelectType.Many,
    isMagritte,
}) => {
    const {
        onSubmit,
        onChangeAddFolderName,
        onChangeFolders,
        onChangeOneFolder,
        onChangeAddFolder,
        getSortedFolders,
        checkedFolders,
        isCheckedAddFolder,
        isLoading,
    } = useResumeFolder({ folders, selectedIdsFolder, onSave, selectType });

    const SelectElement = useMemo(() => {
        if (isMagritte) {
            return selectType === SelectType.One ? Radio : Checkbox;
        }

        return selectType === SelectType.One ? BlokoRadio : BlokoCheckbox;
    }, [selectType, isMagritte]);

    const makeSortedFolders = useCallback(
        (folders: FoldersGroupType = [], selectedIdsFolder: string[]) => {
            return getSortedFolders(folders).map((id) => (
                <Folder
                    CheckElement={SelectElement}
                    key={id}
                    id={String(id)}
                    name={folders?.[id]?.name || ''}
                    selected={selectedIdsFolder.includes(String(id))}
                    onChange={selectType === SelectType.Many ? onChangeFolders : onChangeOneFolder}
                    dataQAPrefix={`${dataQAPrefix}-item`}
                    isMagritte={isMagritte}
                />
            ));
        },
        [SelectElement, dataQAPrefix, getSortedFolders, onChangeFolders, onChangeOneFolder, selectType, isMagritte]
    );

    return isMagritte ? (
        <div className={styles.dropContent}>
            <div className={styles.foldersContainer}>
                {Object.keys(folders.own).length > 0 && (
                    <>
                        <Text typography="subtitle-2-semibold">{trls[TrlKeys.myFolders]}</Text>
                        <VSpacing default={4} />
                        <ul>{makeSortedFolders(folders.own, checkedFolders.current)}</ul>
                        <VSpacing default={16} />
                    </>
                )}
                {Object.keys(folders.shared).length > 0 && (
                    <>
                        <Text typography="subtitle-2-semibold">{trls[TrlKeys.sharedFolders]}</Text>
                        <VSpacing default={4} />
                        <ul>{makeSortedFolders(folders.shared, checkedFolders.current)}</ul>
                        <VSpacing default={16} />
                    </>
                )}
                <Text typography="subtitle-2-semibold">{trls[TrlKeys.createFolder]}</Text>
                <VSpacing default={8} />
                <div className={styles.newFolderWrapper}>
                    <SelectElement
                        name="favoriteFolder"
                        onChange={onChangeAddFolder}
                        checked={isCheckedAddFolder}
                        {...{
                            [SelectElement === Radio ? 'dataQaRadio' : 'dataQaCheckbox']:
                                `${dataQAPrefix}-new-checkbox`,
                        }}
                    />
                    <UncontrolledInput
                        size="medium"
                        onChange={(value) => {
                            onChangeAddFolderName(value);
                        }}
                        data-qa={`${dataQAPrefix}-field`}
                        placeholder={trls[TrlKeys.newFolder]}
                        clearableOnFocus={false}
                    />
                </div>
            </div>
            <VSpacing default={16} />
            <Button
                mode="primary"
                style="accent"
                stretched
                onClick={onSubmit}
                loading={isLoading}
                data-qa={`${dataQAPrefix}-save`}
            >
                {trls[TrlKeys.submit]}
            </Button>
        </div>
    ) : (
        <div className="folders-select folders-select-star">
            <div className="folders-select-list">
                {Object.keys(folders.own).length > 0 && (
                    <Gap bottom>
                        <div className="folders-select-title">
                            <FormItem>
                                <BlokoText strong>{trls[TrlKeys.myFolders]}</BlokoText>
                            </FormItem>
                        </div>
                        <ul>{makeSortedFolders(folders.own, checkedFolders.current)}</ul>
                    </Gap>
                )}
                {Object.keys(folders.shared).length > 0 && (
                    <Gap bottom>
                        <div className="folders-select-title">
                            <FormItem>
                                <BlokoText strong>{trls[TrlKeys.sharedFolders]}</BlokoText>
                            </FormItem>
                        </div>
                        <ul>{makeSortedFolders(folders.shared, checkedFolders.current)}</ul>
                    </Gap>
                )}
                <div className="folders-select-title">
                    <FormItem>
                        <BlokoText strong>{trls[TrlKeys.createFolder]}</BlokoText>
                    </FormItem>
                </div>
                <div>
                    <FormItem>
                        <div className="folders-select-item">
                            <div className="folders-select-checkbox-wrapper">
                                <SelectElement
                                    name="favoriteFolder"
                                    onChange={onChangeAddFolder}
                                    checked={isCheckedAddFolder}
                                    data-qa={`${dataQAPrefix}-new-checkbox`}
                                >
                                    &nbsp;
                                </SelectElement>
                            </div>
                            <InputText
                                scale={InputScale.Small}
                                onChange={onChangeAddFolderName}
                                data-qa={`${dataQAPrefix}-field`}
                                placeholder={trls[TrlKeys.newFolder]}
                            />
                        </div>
                    </FormItem>
                </div>
            </div>
            <BlokoButton
                kind={ButtonKind.Primary}
                stretched
                onClick={onSubmit}
                loading={isLoading && <Loading scale={LoadingScale.Small} />}
                data-qa={`${dataQAPrefix}-save`}
            >
                {trls[TrlKeys.submit]}
            </BlokoButton>
        </div>
    );
};

export default translation(ResumeFolders);
