import { AppStore } from 'src/app/store';
import { VacancyPermission } from 'src/models/vacancy/permissions.types';

export const selectorCanCommunicateWithApplicant = (state: AppStore): boolean => {
    const isHiringManagerExperiment = state.isHiringManagerExperiment;
    const vacancyPermission = state.hiringManagerVacancyPermission;
    const isHiringManager = state.isHiringManager;

    if (!isHiringManagerExperiment) {
        return true;
    }

    if (!vacancyPermission) {
        return !isHiringManager;
    }

    return vacancyPermission.permissions.includes(VacancyPermission.ViewContacts);
};
