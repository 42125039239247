import { useEffect, useState } from 'react';

import { HiringManagerInfo } from 'src/components/HiringManager/types';
import { ManagersList } from 'src/models/employerManagersPage';
import fetcher from 'src/utils/fetcher';

const GET_HIRING_MANAGER_LIST_URL = '/shards/employer/hiring_managers/list';

declare global {
    interface FetcherGetApi {
        [GET_HIRING_MANAGER_LIST_URL]: {
            queryParams: { vacancyId?: string };
            response: {
                hiringManagers: HiringManagerInfo[];
                managers: ManagersList[];
            };
        };
    }
}

type UseFetchHiringManagers = (
    visibility: boolean,
    vacanciesIds?: string[]
) => {
    isLoading: boolean;
    hiringManagers: HiringManagerInfo[];
    activeManagers: ManagersList[];
};

export const useFetchHiringManagers: UseFetchHiringManagers = (visibility: boolean, vacanciesIds?: string[]) => {
    const [hiringManagers, setHiringMangers] = useState<HiringManagerInfo[]>([]);
    const [activeManagers, setActiveManagers] = useState<ManagersList[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await fetcher.get(GET_HIRING_MANAGER_LIST_URL, {
                    params: { vacancyId: vacanciesIds?.length === 1 ? vacanciesIds.toString() : undefined },
                });
                setHiringMangers(response.hiringManagers);
                setActiveManagers(response.managers);
                setIsLoading(false);
            } catch (_) {
                setIsLoading(false);
            }
        };

        if (visibility) {
            void fetchData();
        }
    }, [vacanciesIds, visibility]);

    return {
        isLoading,
        hiringManagers,
        activeManagers,
    };
};
