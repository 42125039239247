import { useState, useEffect, useCallback, useRef } from 'react';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { Text, Button, VSpacing, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import { BANNER_CLOSE_DELAY_MS } from 'src/components/EmployerSearch/ContactFeedbackBanner/consts';
import {
    Props,
    BannerShownAnalyticsData,
    BannerClickedAnalyticsData,
} from 'src/components/EmployerSearch/ContactFeedbackBanner/types';
import {
    getStorageKeyForResumeId,
    setStorageItem,
    getStorageItem,
} from 'src/components/EmployerSearch/ContactFeedbackBanner/utils/storageUtils';

import styles from './styles.less';

const TrlKeys = {
    defaultText: 'resume.contactFeedbackBanner.text.default',
    successText: 'resume.contactFeedbackBanner.text.success',
    buttonNo: 'resume.contactFeedbackBanner.button.no',
    buttonYes: 'resume.contactFeedbackBanner.button.yes',
};

/**
 * Баннер для сбора обратной связи получилось ли связаться с соискателем.
 */
const ContactFeedbackBanner: TranslatedComponent<Props> = ({
    resumeId,
    renderDelay = 0,
    paddingSize = 'medium',
    limitedWidth,
    isPage,
    trls,
}) => {
    const [shouldRender, setShouldRender] = useState(false);
    const [hasAnswer, setHasAnswer] = useState(false);
    const { isMobile } = useBreakpoint();
    const closeTimeoutRef = useRef<NodeJS.Timeout>();
    const hhid = useSelector(({ hhid }) => hhid || '');

    const handleCloseWithTimeout = useCallback(() => {
        closeTimeoutRef.current = setTimeout(() => {
            setShouldRender(false);
        }, BANNER_CLOSE_DELAY_MS);

        // На других вкладках через ивент скрываем баннер сразу же без ожидания таймаута.
        setStorageItem(
            getStorageKeyForResumeId(resumeId),
            {
                disableRender: true,
            },
            true
        );
    }, [setShouldRender, resumeId]);

    const handleAnswer = useCallback(
        (answer: boolean) => {
            const { appearDelay, triggerDate, triggerName, showChance } =
                getStorageItem(getStorageKeyForResumeId(resumeId)) || {};

            if (appearDelay === undefined || !triggerDate || !triggerName || showChance === undefined) {
                return;
            }

            const params: BannerClickedAnalyticsData = {
                appearDelay,
                triggerDate,
                triggerName,
                showChance,
                resumeId,
                hhid,
                answerDate: new Date().getTime(),
                answer,
                hhtmSourceLabel: isPage ? 'resumePage' : 'resumeSnippet',
            };
            Analytics.sendHHEvent('resumeContactFeedbackBannerAnswered', params);

            setHasAnswer(true);
            handleCloseWithTimeout();
        },
        [isPage, resumeId, hhid, setHasAnswer, handleCloseWithTimeout]
    );

    const handleNegativeAnswer = useCallback(() => {
        handleAnswer(false);
    }, [handleAnswer]);

    const handlePositiveAnswer = useCallback(() => {
        handleAnswer(true);
    }, [handleAnswer]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShouldRender(true);

            const { appearDelay, triggerDate, triggerName, showChance } =
                getStorageItem(getStorageKeyForResumeId(resumeId)) || {};

            if (appearDelay === undefined || !triggerDate || !triggerName || showChance === undefined) {
                return;
            }

            const params: BannerShownAnalyticsData = {
                appearDelay,
                triggerDate,
                triggerName,
                showChance,
                resumeId,
                hhid,
                hhtmSourceLabel: isPage ? 'resumePage' : 'resumeSnippet',
            };
            Analytics.sendHHEvent('resumeContactFeedbackBannerShown', params);
        }, renderDelay);

        return () => {
            clearTimeout(timeoutId);
            if (closeTimeoutRef.current) {
                clearTimeout(closeTimeoutRef.current);
            }
        };
    }, [setShouldRender, renderDelay, resumeId, hhid, isPage]);

    if (!shouldRender) {
        return null;
    }

    return (
        <>
            {paddingSize === 'small' && <VSpacing default={12} />}
            {paddingSize === 'medium' && <VSpacing default={12} gteM={24} />}
            {paddingSize === 'large' && <VSpacing default={16} gteM={32} />}
            <div
                className={classnames(styles.banner, {
                    [styles.limitedWidth]: limitedWidth,
                })}
                data-qa="contact-feedback-banner"
            >
                <Text
                    typography={isMobile ? 'label-3-regular' : 'label-2-regular'}
                    style="primary"
                    data-qa="contact-feedback-banner-text"
                >
                    {trls[TrlKeys[!hasAnswer ? 'defaultText' : 'successText']]}
                </Text>
                {!hasAnswer && (
                    <div className={styles.buttons}>
                        <Button
                            size="small"
                            style="neutral"
                            mode="primary"
                            data-qa="answer-no-button"
                            onClick={handleNegativeAnswer}
                        >
                            {trls[TrlKeys.buttonNo]}
                        </Button>
                        <Button
                            size="small"
                            style="neutral"
                            mode="primary"
                            data-qa="answer-yes-button"
                            onClick={handlePositiveAnswer}
                        >
                            {trls[TrlKeys.buttonYes]}
                        </Button>
                    </div>
                )}
            </div>
            {paddingSize === 'large' && <VSpacing default={16} gteM={16} />}
        </>
    );
};

export default translation(ContactFeedbackBanner);
