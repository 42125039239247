import { useMemo } from 'react';

import { getRandomIntInclusive } from 'src/components/EmployerSearch/ContactFeedbackBanner/utils/commonUtils';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

type IsBannerAvailable = boolean;
type AppearanceDelay = number;
type AppearanceChance = number;

const Features = {
    resumeFeedbackOnContactBannerAppearanceDelays: 'resume_feedback_on_contact_banner_appearance_delays',
    resumeFeedbackOnContactBannerAppearanceChances: 'resume_feedback_on_contact_banner_appearance_chances',
};

export const useBannerSettings = (isAllowedPage: boolean): [IsBannerAvailable, AppearanceDelay, AppearanceChance] => {
    const resumeFeedbackOnContactBannerAppearanceDelays = useSelector(
        (state) => state.features[Features.resumeFeedbackOnContactBannerAppearanceDelays] as string
    );
    const resumeFeedbackOnContactBannerAppearanceChances = useSelector(
        (state) => state.features[Features.resumeFeedbackOnContactBannerAppearanceChances] as string
    );
    const userType = useSelector(({ userType }) => userType);
    const isEmployer = userType === UserType.Employer;

    return useMemo(() => {
        const appearanceDelay = resumeFeedbackOnContactBannerAppearanceDelays?.split(',')[getRandomIntInclusive(0, 9)];
        const appearanceChance =
            resumeFeedbackOnContactBannerAppearanceChances?.split(',')[getRandomIntInclusive(0, 9)];
        const isBannerAvailable =
            !!resumeFeedbackOnContactBannerAppearanceDelays &&
            !!resumeFeedbackOnContactBannerAppearanceChances &&
            isEmployer &&
            isAllowedPage;

        return [isBannerAvailable, Number(appearanceDelay), Number(appearanceChance)];
    }, [
        resumeFeedbackOnContactBannerAppearanceDelays,
        resumeFeedbackOnContactBannerAppearanceChances,
        isAllowedPage,
        isEmployer,
    ]);
};
