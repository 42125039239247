import { Dispatch } from 'react';
import { AxiosError } from 'axios';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { Push } from '@hh.ru/redux-spa-middleware';

import { AppStore } from 'src/app/store';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import discardCandidatesNotification from 'src/components/Notifications/VacancyResponses';
import { discardCandidates } from 'src/models/candidatesList';
import { ComplaintReasonType } from 'src/models/complaintReasonTypes';
import { removeCheckedTopic } from 'src/models/employer/vacancyresponses/checkedTopics';
import { ResumeComplaint } from 'src/models/resumesComplaint';
import fetcher from 'src/utils/fetcher';

import {
    completionBlacklistError,
    completionBlacklistSuccess,
    completionDefaultError,
} from 'src/components/ResumeComplaint/Notifications';

const COMPLAINT_URL = '/employer/negotiations/complaint';

const BLACKLIST_EXCEEDED = 'BLACKLIST_EXCEEDED';

const isBlacklistExceededError = (error: AxiosError<{ error: string }>): boolean => {
    return error.response?.data?.error === BLACKLIST_EXCEEDED;
};

declare global {
    interface FetcherPostApi {
        [COMPLAINT_URL]: {
            queryParams: void;
            body: {
                vacancyId: number;
                resumeHash: string;
                topicId: number;
                state: string;
                reason: string | unknown;
                toBlackList: boolean;
                reasonComment: string;
            };
            response: { status: string; responseJSON: { error: string } };
        };
    }
}

interface NegotiationComplaintProps {
    resumesComplaint: ResumeComplaint;
    reason: string | null;
    resumeHash: string;
    blackList: boolean;
    reasonComment: string;
    isResponse: boolean;
    complaintReasonTypes: ComplaintReasonType[];
    addNotification: AddNotification;
    push: Push;
    isEmployerSubstatesExp?: boolean;
}

export default ({
        isResponse,
        resumesComplaint,
        complaintReasonTypes,
        reason,
        resumeHash,
        blackList,
        reasonComment,
        addNotification,
        push,
        isEmployerSubstatesExp = false,
    }: NegotiationComplaintProps): ThunkAction<Promise<number | void>, AppStore, unknown, AnyAction> =>
    (dispatch: Dispatch<unknown>, getState) => {
        const topicList = getState().candidatesList?.shortTopicList?.topicList;
        const { vacancyId, topicId, redirectUrl } = resumesComplaint;
        const withComment = complaintReasonTypes.find((type) => reason === type.reasonType)?.needComment;
        const params = {
            vacancyId,
            resumeHash,
            topicId,
            state: 'discard_by_employer',
            reason,
            toBlackList: blackList,
            reasonComment: withComment ? reasonComment : '',
        };

        return fetcher.postFormData(COMPLAINT_URL, params).then(
            ({ status }) => {
                if (params.toBlackList) {
                    addNotification(completionBlacklistSuccess);
                    if (isResponse && topicList) {
                        const resumesToDiscard = Object.values(topicList)
                            .filter((topic) => topic.applicantUserId === topicList[topicId].applicantUserId)
                            .map((topic) => topic.id.toString());
                        dispatch([
                            removeCheckedTopic(resumesToDiscard),
                            discardCandidates({ topicIds: resumesToDiscard, isSubstatesExp: isEmployerSubstatesExp }),
                        ]);
                    }
                } else if (isResponse) {
                    addNotification(discardCandidatesNotification, { props: { count: 1 } });
                    dispatch([
                        removeCheckedTopic([topicId.toString()]),
                        discardCandidates({ topicIds: [topicId.toString()], isSubstatesExp: isEmployerSubstatesExp }),
                    ]);
                }
                !isResponse && redirectUrl && push(redirectUrl);

                return status;
            },
            (err: AxiosError<{ error: string }, unknown>) => {
                if (isBlacklistExceededError(err)) {
                    addNotification(completionBlacklistError);
                    return;
                }
                addNotification(completionDefaultError);
            }
        );
    };
