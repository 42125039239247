import { ReactNode, useCallback, useMemo } from 'react';

import { Banner, Button, Card, Select, SelectOption, Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { HiringManagerInfo } from 'src/components/HiringManager/types';
import { getIsManagerMCP } from 'src/components/HiringManager/utils/getIsManagerMCP';
import SelectFixedHeight from 'src/components/VacancyFunnel/SelectFixedHeight';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ManagerType } from 'src/models/employerManagers.types';
import { ManagersList } from 'src/models/employerManagersPage';

const TrlKeys = {
    selectPlaceholder: 'employer.vacancy.share.modal.selectPlaceholder',
    applyButton: 'employer.hiringManager.suggest.applyButton',
    mcp: 'employer.hiringManager.mcp',
    nonMcp: 'employer.hiringManager.nonMcp',
    hiringManager: 'employer.hiringManager.hiringManager',
    noOptions: 'employer.hiringManager.suggest.noOptions',
};

interface HiringManagerSuggestProps {
    name: string;
    hiringManagers: HiringManagerInfo[];
    activeManagers: ManagersList[];
    isLoading: boolean;
    onChange: (value: string[]) => void;
    value: string[];
    setSelectedManagers: (managers: ManagersList[]) => void;
    error?: string;
    isInvalid?: boolean;
    description?: ReactNode;
    filterHiringManagers?: boolean;
}

const HiringManagerSuggest: TranslatedComponent<HiringManagerSuggestProps> = ({
    trls,
    onChange,
    name,
    value,
    setSelectedManagers,
    error,
    isInvalid,
    description,
    filterHiringManagers = false,
    activeManagers,
    hiringManagers,
    isLoading,
}) => {
    const currentManagerId = useSelector((state) => state.employerManager?.id);

    const options: SelectOption[] = useMemo(() => {
        const [mcp, nonMcp, hiringManager] = activeManagers.reduce(
            (acc, manager) => {
                const isManagerExcluded = filterHiringManagers
                    ? hiringManagers.length && hiringManagers.find((hm) => hm.employerManagerId === manager.id)
                    : manager.id.toString() === currentManagerId;
                if (isManagerExcluded) {
                    return acc;
                }
                let idx = getIsManagerMCP(manager) ? 0 : 1;
                if (manager.type === ManagerType.HiringManager) {
                    idx = 2;
                }
                acc[idx].push({ label: manager.fullName, value: manager.id.toString() });
                return acc;
            },
            [[], [], []] as Array<SelectOption[]>
        );
        return ([] as SelectOption[])
            .concat(
                hiringManager.length
                    ? [
                          {
                              type: 'delimiter',
                              value: 'hiringManager',
                              label: trls[TrlKeys.hiringManager],
                          },
                      ]
                    : []
            )
            .concat(hiringManager)
            .concat(nonMcp.length ? [{ type: 'delimiter', value: 'nonMcp', label: trls[TrlKeys.nonMcp] }] : [])
            .concat(nonMcp)
            .concat(mcp.length ? [{ type: 'delimiter', value: 'mcp', label: trls[TrlKeys.mcp] }] : [])
            .concat(mcp);
    }, [activeManagers, trls, filterHiringManagers, hiringManagers, currentManagerId]);

    const onClose = useCallback(() => {
        const newVal = activeManagers.filter((manager) => value.includes(manager.id.toString()));
        setSelectedManagers(newVal);
        onChange(newVal.map((manager) => manager.id.toString()));
    }, [activeManagers, onChange, value, setSelectedManagers]);

    if (isLoading || !options.length) {
        return (
            <Card.Skeleton height={56} loading={isLoading} borderRadius={16} stretched>
                <Banner
                    stretched
                    showClose={false}
                    style="warning"
                    content={<Text typography="paragraph-2-regular">{trls[TrlKeys.noOptions]}</Text>}
                />
            </Card.Skeleton>
        );
    }

    return (
        <SelectFixedHeight multiple>
            <Select
                multiple
                type="checkbox"
                name={name}
                triggerProps={{
                    size: 'large',
                    label: trls[TrlKeys.selectPlaceholder],
                    disabled: isLoading,
                    invalid: isInvalid,
                    errorMessage: error,
                    description,
                    stretched: true,
                }}
                searchable
                options={options}
                applyChangesButton={
                    <Button mode="primary" style="accent">
                        {trls[TrlKeys.applyButton]}
                    </Button>
                }
                onChange={onChange}
                onDropClose={onClose}
                onBottomSheetClose={onClose}
                value={value || []}
            />
        </SelectFixedHeight>
    );
};

export default translation(HiringManagerSuggest);
