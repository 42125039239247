import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';
import support from 'bloko/common/supports';

import { TIMESTAMP_7_DAYS_IN_MC } from 'src/components/EmployerSearch/ContactFeedbackBanner/consts';
import { StorageItem } from 'src/components/EmployerSearch/ContactFeedbackBanner/types';

import { getRandomIntInclusive } from 'src/components/EmployerSearch/ContactFeedbackBanner/utils/commonUtils';

export const getStorageKeyForResumeId = (resumeId: string): string => {
    return `cfb_${resumeId}`;
};

const checkChance = (chance: number): boolean => {
    const randomNumber = getRandomIntInclusive(1, 100);
    return randomNumber <= chance;
};

export const getStorageItem = (storageKey: string): StorageItem | null => {
    const item = LocalStorageWrapper.getItem(storageKey);
    return item ? (JSON.parse(item) as StorageItem) : null;
};

export const setStorageItem = (
    storageKey: string,
    item: StorageItem | Partial<StorageItem>,
    joinData?: boolean
): StorageItem => {
    const oldFields = getStorageItem(storageKey) || {};
    const updatedItem = {
        ...(joinData && oldFields),
        ...item,
    };

    LocalStorageWrapper.setItem(storageKey, JSON.stringify(updatedItem));
    return updatedItem as StorageItem;
};

/** Спустя 7 дней после триггера принудительно перестаем показывать баннер. */
const checkFor7Days = (storageItem: StorageItem): boolean => {
    const triggerTimestamp = storageItem.triggerDate;

    // Для баннера когда-то уже записывалась информация, но до срабатывания триггера дело не дошло.
    if (!triggerTimestamp) {
        return true;
    }

    const currentTimestamp = new Date().getTime();
    return currentTimestamp - triggerTimestamp < TIMESTAMP_7_DAYS_IN_MC;
};

const checkForBlockers = (storageItem: StorageItem): boolean => {
    return !storageItem.disableRender;
};

/**
 * Контроллер для проверки и расчетов всех условий для отрисовки баннера.
 *
 * @description Возвращает флаг отсутствия блокеров для рендера и сам объект из локального хранилища.
 */
export const handleStorageItem = (
    isBannerExpAvailable: boolean,
    resumeId: string,
    renderDelay = 0,
    showChance = 5
): [boolean, StorageItem | null] => {
    if (!isBannerExpAvailable || !support.localStorage()) {
        return [false, null];
    }

    const storageKey = getStorageKeyForResumeId(resumeId);
    let storageItem = getStorageItem(storageKey);

    if (
        !!storageItem?.triggerDate &&
        (storageItem?.showChance === undefined || storageItem?.appearDelay === undefined)
    ) {
        // Удаляем неправильно сгенерированные данные после непредвиденного запуска экспа.
        LocalStorageWrapper.removeItem(storageKey);
        storageItem = null;
    }

    if (storageItem) {
        const isLessThan7Days = checkFor7Days(storageItem);
        const triggerTimestamp = storageItem.triggerDate;

        let updatedItem;
        if (!isLessThan7Days) {
            updatedItem = setStorageItem(
                storageKey,
                {
                    disableRender: true,
                },
                true
            );
        } else if (!triggerTimestamp) {
            const isLuckyToSeeBanner = checkChance(showChance);
            updatedItem = setStorageItem(storageKey, {
                appearDelay: renderDelay,
                showChance,
                disableRender: !isLuckyToSeeBanner,
            });
        }

        return [isLessThan7Days && checkForBlockers(updatedItem || storageItem), updatedItem || storageItem];
    }

    const isLuckyToSeeBanner = checkChance(showChance);
    const newItem = setStorageItem(storageKey, {
        appearDelay: renderDelay,
        showChance,
        disableRender: !isLuckyToSeeBanner,
    });

    return [isLuckyToSeeBanner, newItem];
};
