import { useDispatch } from 'react-redux';

import { useSelector } from 'src/hooks/useSelector';
import { markTopicsAsViewed } from 'src/models/candidatesList';
import fetcher from 'src/utils/fetcher';

const READ_TOPICS_URL = '/employer/negotiations/read';

declare global {
    interface FetcherGetApi {
        [READ_TOPICS_URL]: {
            queryParams: {
                vacancyId: string | number;
                topicId: string;
            };
            response: void;
        };
    }
}

type UseMarkAsReadHook = () => (options: {
    vacancyId: string | number;
    topicIds: string[];
    onSuccess?: () => void;
    onError?: (error: unknown) => void;
}) => Promise<void>;

export const useMarkAsRead: UseMarkAsReadHook = () => {
    const dispatch = useDispatch();
    const isSubstatesExp = useSelector((state) => state.isEmployerSubstatesExp);

    return async ({ vacancyId, topicIds, onSuccess, onError = console.error }) => {
        try {
            await fetcher.get(READ_TOPICS_URL, {
                params: { vacancyId, topicId: topicIds.join(',') },
            });
        } catch (error) {
            onError(error);
            return;
        }

        dispatch(markTopicsAsViewed({ topicIds, isSubstatesExp }));
        onSuccess?.();
    };
};
