import { RefObject } from 'react';

const MAGRITTE_PADDING = 16;

const APPLY_BUTTON_HEIGHT = 72;

interface GetMaxHeightParams {
    ref: RefObject<HTMLElement>;
    defaultValue?: number;
    withApplyButton?: boolean;
}

export const getMaxHeight = ({ ref, defaultValue = 460, withApplyButton = false }: GetMaxHeightParams): number => {
    if (ref.current && typeof window !== 'undefined') {
        return (
            window.innerHeight -
            ref.current.getBoundingClientRect().bottom -
            2 * MAGRITTE_PADDING -
            (withApplyButton ? APPLY_BUTTON_HEIGHT + MAGRITTE_PADDING : 0)
        );
    }

    return defaultValue;
};
